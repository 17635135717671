<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="listQuery.keyword" placeholder="请输入姓名/手机号码" style="width: 220px;" class="filter-item" clearable/>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-edit" @click="dialogVisible = true">添加</el-button>
    </div>
    <div class="filter-container">
      <el-button-group>
        <el-button type="danger" size="small" @click="handleBatchDelete" :disabled="ids.length <= 0">批量删除</el-button>
      </el-button-group>
    </div>

    <el-table v-loading="listLoading" :data="list" @selection-change="handleSelectionChange" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column type="selection" align="center" width="40"></el-table-column>
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="用户" min-width="110" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.user" style="display: flex;flex-direction:column;align-items:center;text-align:center;">
            <img :src="scope.row.user.avatar" class="user-avatar">
            <span>{{ scope.row.user.nickname }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="姓名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user.name }}
        </template>
      </el-table-column>
      <el-table-column label="手机号码" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user.phone }}
        </template>
      </el-table-column>
      <el-table-column label="迟到次数" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.late_count }}
        </template>
      </el-table-column>
      <el-table-column label="早退次数" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.early_leave_count }}
        </template>
      </el-table-column>
      <el-table-column label="旷工次数" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.absenteeism_count }}
        </template>
      </el-table-column>
      <el-table-column label="漏打下班次数" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.forget_clock_out_count }}
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="300" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button type="danger" size="mini" @click="handleDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
      <el-form label-position="left" label-width="90px">
        <el-form-item v-if="!form.id" label="用户">
          <el-select v-model="form.user_id" placeholder="输入昵称/手机号/姓名进行搜索" filterable remote :remote-method="userFilter"  :loading="userFilterLoading" style="width:340px" clearable>
            <el-option v-for="item in users" :key="item.id" :label="item.nickname" :value="item.id">
              <div style="display: flex;align-items:center;text-align:center;">
                <img :src="item.avatar" style="width: 26px;height:26px;border-radius:4px;margin-right:4px;">
                <span>{{ item.nickname }} / {{item.phone}} / {{item.name}}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
      },
      dialogVisible: false,
      btnLoading: false,
      form: {
        id: "",
        school_id: "",
        user_id: "",
        name: "",
        phone: "",
      },
      users: [],
      userFilterLoading: false,
      // 批量删除
      ids: [],
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
    },
    dialogVisible(newVal, oldVal) {
      // 编辑框一异隐藏，马上清除旧数据
      if (newVal === false) {
        this.form = {
          id: "",
          school_id: "",
          user_id: "",
          name: "",
          phone: "",
        };
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/distributeman/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleDel(item) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/distributeman/del",
          method: "get",
          params: {
            id: item.id
          }
        }).then(() => {
          // 删除最后一条数据时无数据问题
          this.list.length <= 1 ? this.listQuery.page-- : false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    userFilter(val) {
      this.userFilterLoading = true
      request({
          url: "/api/backend/user/list",
          method: "get",
          params: {
            page: 1,
            limit: 10,
            school_id: this.school_id,
            keyword: val,
          }
        }).then(response => {
          this.users = response.data.data;
        }).finally(()=>{
          this.userFilterLoading = false
        })
    },
    saveData() {
      this.form.school_id = this.school_id;
      if (!this.form.user_id) {
        this.$message({
          type: "warning",
          message: "请先选择用户"
        });
        return;
      }
      this.btnLoading = true;
      request({
        url: "/api/backend/distributeman/save",
        method: "post",
        data: this.form
      }).then(() => {
        this.dialogVisible = false;
        this.getList();
        this.$message({
          type: "success",
          message: "操作成功!"
        });
      }).finally(()=>{
        this.btnLoading = false;
      })
    },
    // 批量选中
    handleSelectionChange(val) {
      let ids = [];
      val.forEach(element => {
        ids.push(element.id);
      });
      this.ids = ids;
    },
    // 批量删除
    handleBatchDelete() {
      this.$confirm("确定要删除选中分餐员吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/distributeman/batchDelete",
          method: "post",
          data: {
            ids: this.ids
          }
        }).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.floor-container {
  display: inline-block;
  width: 160px;
  height: 160px;
  margin-right: 25px;
  margin-bottom: 25px;
}
</style>
